import React from "react";
import "./HubPreviewCard.styles.scss";
import hubImg from "../../assets/images/cover.png";
import profileAvatar from "../../assets/images/SVG/avatar.svg";
import { Avatar, Button, Card } from "antd";

const HubPreviewCard = ({ hubData, hubCrewList, onPreviewClick }) => {
  return (
    <Card className="hub-card">
      <div className="details">
        <img src={hubImg} alt="hub" width="50px" height="50px" />
        <div className="content">
          <p className="hub-name">{hubData?.hub_name}</p>
          <div className="avatar-container">
            <Avatar.Group
              max={{
                count: 3,
                popover: {
                  trigger: "click",
                },
                style: {
                  cursor: "pointer",
                },
              }}
              size={24}
            >
              {hubCrewList?.map((crew) => (
                <Avatar
                  key={crew?.crew_id}
                  className={hubCrewList?.length > 1 ? "marginSide" : ""}
                  src={
                    crew?.profile_picture_url === null ||
                    crew?.profile_picture_url.endsWith("default.jpg")
                      ? profileAvatar
                      : crew?.profile_picture_url
                  }
                />
              ))}
            </Avatar.Group>
          </div>
        </div>
      </div>
      <Button
        type="default"
        className="preview-btn"
        shape="round"
        onClick={onPreviewClick}
      >
        Preview
      </Button>
    </Card>
  );
};

export default HubPreviewCard;
