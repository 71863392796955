import { Dropdown, Input, message, Spin } from "antd";
import axios from "axios";
import { useState } from "react";
import { throttle } from "lodash";
import MyGoogleMap from "../../GoogleMap/GoogleMap";
import { LoadingOutlined } from "@ant-design/icons";

const LocationInput = ({ placeholder, isLocation, locationSvg, form }) => {
  const [location, setLocation] = useState("");
  const [currentPosition, setCurrentPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 51.505, lng: -0.09 });
  const [searchResults, setSearchResults] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state
  const apiKey = "66c5cd73c69f6765336363srqf95d45";

  const handleLocationIconClick = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentPosition({ latitude, longitude });
          setMapCenter({ lat: latitude, lng: longitude });

          try {
            const response = await axios.get(
              `https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=${apiKey}`,
              {
                withCredentials: false,
              }
            );
            console.log({ response });
            const locationName =
              response?.data?.display_name || "Unknown location";
            setLocation(locationName);
            form.setFieldValue("location", [
              latitude.toString(),
              longitude.toString(),
            ]);
          } catch (error) {
            message.error("Error fetching location");
            console.error("Error fetching location name:", error);
            setLoading(false);
          } finally {
            setLoading(false); // Stop loading after the request is done
          }
        },
        (error) => {
          console.error("Error fetching location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleLocationSearch = throttle(async (value) => {
    if (value.length >= 4) {
      try {
        const response = await axios.get(
          `https://geocode.maps.co/search?q=${value}&api_key=${apiKey}`,
          {
            withCredentials: false,
          }
        );
        console.log({ response });
        setSearchResults(response?.data);
        setDropdownVisible(true);
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    }
  }, 2000);

  const handleInputChange = (e) => {
    const query = e.target.value;
    setLocation(query);
    if (query === "") {
      setSearchResults([]);
      setDropdownVisible(false);
    } else {
      handleLocationSearch(query);
    }
  };

  const locationIcon = (
    <img
      style={{ cursor: "pointer" }}
      src={locationSvg}
      alt="location"
      onClick={handleLocationIconClick}
    />
  );

  const handleSelectLocation = (locationData) => {
    const { display_name, lat, lon } = locationData;
    setLocation(display_name);
    setCurrentPosition({
      latitude: parseFloat(lat),
      longitude: parseFloat(lon),
    });
    setMapCenter({ lat: parseFloat(lat), lng: parseFloat(lon) });
    setDropdownVisible(false);
    form.setFieldValue("location", [lat, lon]);
  };

  const items = searchResults.map((result, index) => ({
    key: index,
    label: result?.display_name,
    onClick: () => handleSelectLocation(result),
  }));

  const mapContainerStyle = {
    width: "100%",
    height: "190px",
  };

  return (
    <div>
      <Dropdown
        className="map-dropdown"
        overlayClassName="map-dropdown-overlay"
        menu={{ items, selectable: true }}
        open={dropdownVisible}
        onOpenChange={setDropdownVisible}
        trigger={["click"]}
      >
        <Input
          placeholder={placeholder}
          suffix={
            loading ? (
              <Spin indicator={<LoadingOutlined spin />} size="small" />
            ) : (
              locationIcon
            )
          }
          value={location}
          onChange={handleInputChange}
          // readOnly={isLocation}
        />
      </Dropdown>
      <MyGoogleMap
        position={currentPosition}
        mapContainerStyle={mapContainerStyle}
        center={mapCenter}
        mapClassName="form-map"
      />
    </div>
  );
};

export default LocationInput;
