import { Avatar, Button, Modal } from "antd";
import profileAvatar from "../../assets/images/SVG/avatar-blue.svg";
import "./UserProfile.styles.scss";
import Cookies from "js-cookie";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import api from "../../api/api";

const UserProfile = ({ currentUser }) => {
  const navigate = useNavigate();
  const { confirm } = Modal;
  const queryClient = useQueryClient();

  const logoutMutation = useMutation(() => api.logoutUser(), {
    onSuccess: async () => {
      Cookies.remove("csrftoken");
      Cookies.remove("sessionid");
      localStorage.clear();

      console.log("Logout successful!");
      queryClient.setQueryData("currentUser", null);
      window.location.reload();
      // navigate(`/login`);
    },
    onError: (error) => {
      console.error("Logout error:", error);
    },
  });

  const signoutClick = async () => {
    try {
      await logoutMutation.mutateAsync();
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const showConfirmation = () => {
    confirm({
      title: `Confirmation`,
      content: "Are you sure that you want to signout?",
      icon: null,
      okText: "Confirm",
      okType: "text",
      cancelText: "Cancel",
      cancelButtonProps: { type: "text" },
      onOk() {
        signoutClick();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <div className="userProfile-container">
      <Button
        loading={logoutMutation.isLoading}
        className="signout-btn"
        onClick={showConfirmation}
      >
        Sign out
      </Button>
      <div className="profile-section">
        <Avatar
          size={56}
          src={
            currentUser?.profile_picture_url === null ||
            currentUser?.profile_picture_url.endsWith("default.jpg")
              ? profileAvatar
              : currentUser?.profile_picture_url
          }
        />
        <div className="name-email">
          <span className="user-name">
            {currentUser?.first_name} {currentUser?.last_name}
          </span>
          <span className="user-email">{currentUser?.email}</span>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
