import { Divider, Spin } from "antd";
import AssignmentDetails from "../AssignmentDetails/AssignmentDetails.component";
import "./DashBoard.styles.scss";
import GoalSection from "../GoalSection/GoalSection.component";
import { useUserContext } from "../../context/UserContext";
import GroceryForm from "../Forms/GroceryForm/GroceryForm.component";
import { useUnitChoices } from "../../context/UnitChoicesContext";
import GroceryList from "../GroceryList/GroceryList.component";
import api from "../../api/api";
import { useQuery } from "react-query";

const DashBoard = () => {
  const { currentUser } = useUserContext();
  const { data } = useUnitChoices();
  const currentUserAssignments = currentUser?.to_do_assignments;
  const fetchSharedList = async () => {
    const response = await api.getSharedList();
    return response;
  };

  const { data: groceryList, isLoading } = useQuery(
    "sharedGroceryList",
    fetchSharedList
  );
  console.log({ groceryList });

  if (isLoading) {
    return <Spin />;
  }

  return (
    <div className="dashboard-container">
      <h1 className="page-heading">Dashboard</h1>
      <div className="tasks-goalsection">
        <div className="task-list">
          <div className="task-type-heading">
            <span>Grocery List</span>
          </div>
          <GroceryList groceryList={groceryList} unitChoices={data} />
          <GroceryForm unitChoices={data} />
          <div className="task-type-heading">
            <span>My Tasks</span>
          </div>
          <div className="assignmentDetails">
            <AssignmentDetails assignments={currentUserAssignments} />
          </div>
        </div>
        <Divider type="vertical" className="v-divider divider-color" />
        <Divider className="h-divider divider-color" />
        <div className="goal-container">
          <GoalSection />
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
