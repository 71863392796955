import { Avatar, Button, Card } from "antd";
import hubImg from "../../assets/images/cover.png";
import profileAvatar from "../../assets/images/SVG/avatar.svg";
import api from "../../api/api";
import { useState } from "react";
import "./HubSearchResult.styles.scss";
import TagManager from "react-gtm-module";
import { useNavigate, useParams } from "react-router";

const HubSearchResult = ({ searchResult }) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(false);
  const handleButtonClick = async (e, result) => {
    try {
      const response = await api.sendInvitationRequest({
        target_hub: result?.hub_id,
      });
      if (response?.status === 201) {
        setDisableButton((prev) => ({
          ...prev,
          [result?.hub_id]: true,
        }));
        const eventData = {
          gtmId: "GTM-N5PG3HTR",
          dataLayer: {
            event: "HubRequest",
            HubName: result?.hub_name,
          },
        };

        // Push data to the dataLayer
        TagManager.dataLayer(eventData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="hub-result">
      {searchResult?.map((result) => (
        <Card
          className="result-card"
          key={result?.hub_id}
          hoverable
          onClick={(e) => {
            e.preventDefault();
            navigate(`/user/${userId}/hub-view/${result?.hub_id}/`);
          }}
        >
          <div className="details">
            <img src={hubImg} alt="hub" width="50px" height="50px" />
            <div className="content">
              <p className="hub-name">{result?.hub_name}</p>
              <div className="avatar-container">
                <Avatar.Group
                  max={{
                    count: 4,
                    popover: {
                      trigger: "click",
                    },
                    style: {
                      cursor: "pointer",
                    },
                  }}
                  size={24}
                >
                  {result?.crew_info?.map((crew) => (
                    <Avatar
                      key={crew?.crew_id}
                      className={
                        result?.crew_info?.length > 1 ? "marginSide" : ""
                      }
                      src={
                        crew?.profile_picture_url === null ||
                        crew?.profile_picture_url.endsWith("default.jpg")
                          ? profileAvatar
                          : crew?.profile_picture_url
                      }
                    />
                  ))}
                </Avatar.Group>
              </div>
            </div>
          </div>
          <div className="button-container">
            {result?.remaining_crew_slots === 0 ? (
              <Button
                type="default"
                className="invite-btn"
                shape="round"
                disabled
              >
                Closed
              </Button>
            ) : result?.invitation_status === "invited" ||
              result?.invitation_status === "requested" ? ( // need change here accordng to backend changes
              <Button
                className="btn-requested invite-btn"
                shape="round"
                disabled
              >
                Requested
              </Button>
            ) : (
              <Button
                className={`btn-${
                  disableButton[result?.hub_id] ? "requested" : "blue"
                } invite-btn`}
                shape="round"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent navigation when clicking on the button
                  handleButtonClick(e, result);
                }}
                disabled={disableButton[result?.hub_id]} // Disable the button if the response is successful
              >
                {disableButton[result?.hub_id] ? "Requested" : "Request"}
              </Button>
            )}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default HubSearchResult;
