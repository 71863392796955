import axios from "axios";

axios.defaults.baseURL = "https://devapi.cohubitate.com";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

const getAssignments = async () => {
  try {
    const response = await axios.get(`/api/assignments/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const createAssignments = async (values) => {
  try {
    const response = await axios.post(`/api/assignments/`, values);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const editAssignment = async (assignmentId, values) => {
  try {
    const response = await axios.put(
      `/api/assignments/${assignmentId}/`,
      values
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const seriesEditAssignment = async (values) => {
  try {
    const { repeat_id, ...rest } = values; // Destructure repeat_id from values
    const response = await axios.post(`/api/assignments/`, {
      ...rest, // Include all other form values
      repeat_id, // Include repeat_id in the request body
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

const deleteAssignment = async (assignmentId, options = {}) => {
  try {
    const { repeat_id } = options;
    // Include repeat_id in the request body if provided
    const data = repeat_id ? { repeat_id } : {};
    const response = await axios.delete(`/api/assignments/${assignmentId}/`, {
      data,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

const getParticipants = async () => {
  try {
    const response = await axios.get(`/api/crew-members/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const editUser = async (userId, values) => {
  try {
    const response = await axios.put(`/edit-user/${userId}/`, values);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const uploadProfilePicture = async (userId, values) => {
  try {
    const response = await axios.post(
      `/api/crews/${userId}/upload_profile_picture/`,
      values
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const uploadHubPictures = async (hubId, formData) => {
  try {
    const response = await axios.patch(
      `/api/hub-image-upload/${hubId}/`,
      formData
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getFormChoices = async () => {
  try {
    const response = await axios.get(`/api/crew-form-choices/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getHubFormChoices = async () => {
  try {
    const response = await axios.get(`/api/hub-form-choices/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getProfileDetails = async (userId) => {
  try {
    const response = await axios.get(`/api/profile-details/${userId}/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getHubProfileDetails = async (hubId) => {
  try {
    const response = await axios.get(`/api/hub-form/${hubId}/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const submitProfileDetails = async (values, userId) => {
  try {
    const response = await axios.post(
      `/api/profile-details/${userId}/`,
      values
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const submitHubDetails = async (values, hubId) => {
  try {
    const response = await axios.post(`/api/hub-form/${hubId}/`, values);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const AddCrewInHub = async (hubId, values) => {
  try {
    const response = await axios.post(
      `/hubs/${hubId}/add_crew_by_id_or_name/`,
      values
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const RemoveCrewFromHub = async (hubId, participantId) => {
  try {
    const response = await axios.post(
      `/api/hubs/${hubId}/remove_crew_by_id_or_name/`,
      { crew_id: participantId }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const deleteUserData = async () => {
  try {
    const response = await axios.post(`/api/crew-members/delete_crew/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const createHub = async (values) => {
  try {
    const response = await axios.post(`/api/hubs/`, values);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getHubDetails = async (hubId) => {
  try {
    const response = await axios.get(`/api/hubs/${hubId}/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getHubAssignments = async (hubId, todoNum, completedNum) => {
  try {
    const response = await axios.get(
      `/api/next-assignments/?hub_id=${hubId}&to_do_page=${todoNum}&completed_page=${completedNum}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getHubCrew = async (hubId) => {
  try {
    const response = await axios.get(`/api/hubs/${hubId}/crew_members/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const leaveHub = async () => {
  try {
    const response = await axios.post(`/api/hubs/leave_hub/`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(error?.response?.data?.error);
  }
};

const getAssignmentDetails = async (assignmentId) => {
  try {
    const response = await axios.get(`/api/assignments/${assignmentId}/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getUserDetailsByEmail = async (hubId, values) => {
  try {
    const response = await axios.post(
      `/api/hubs/${hubId}/fetch_crew_details/`,
      values
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("User not found with the provided email.");
  }
};

const registerUser = async (userData) => {
  try {
    const response = await axios.post(`/register/`, userData);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error(error?.response?.data?.email);
  }
};

const loginUser = async (userData) => {
  try {
    const response = await axios.post(`/login/`, userData);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error(error?.response?.data?.error);
  }
};

const getlogin = async () => {
  try {
    const response = await axios.get(/login/);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const logoutUser = async (userData) => {
  try {
    const response = await axios.post(`/logout/`, userData);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const getCurrentUser = async () => {
  try {
    const response = await axios.get("/current-user/");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const searchHub = async (value) => {
  try {
    const response = await axios.get(`/search-hub/?search=${value}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const searchCrew = async (value) => {
  try {
    const response = await axios.get(`/search-crew/?search=${value}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const sendInvitationRequest = async (body) => {
  try {
    const response = await axios.post(`/api/invite_request/`, body);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error(error?.response?.data?.error);
  }
};

const viewInvitationRequest = async () => {
  try {
    const response = await axios.get(`/api/invite_request/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const confirmInvitationRequest = async (id, body) => {
  try {
    const response = await axios.patch(`/api/invite_request/${id}/`, body);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const cancelInvitationRequest = async (id) => {
  try {
    const response = await axios.delete(`/api/invite_request/${id}/`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const makeAdmin = async (hubId, body) => {
  try {
    const response = await axios.post(
      `/api/hubs/${hubId}/assign_new_admin/`,
      body
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const removeAdmin = async (hubId, body) => {
  try {
    const response = await axios.post(`/api/hubs/${hubId}/remove_admin/`, body);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error(error?.response?.data?.error);
  }
};

const sendFCMToken = async (token) => {
  try {
    const response = await axios.post(`/fcm-token/`, {
      fcm_token: token,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

const getNotifications = async () => {
  try {
    const response = await axios.get(`/api/notifications/`);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

const putNotification = async (id) => {
  try {
    const response = await axios.put(`/api/notifications/${id}/`, {
      read_receipt: true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

const submitFeedback = async (values) => {
  try {
    const response = await axios.post(`/api/feedback/`, values);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const support = async (formData) => {
  try {
    const response = await axios.post(`/api/support/`, formData);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const verifyEmail = async () => {
  try {
    const response = await axios.get(`/verify-email/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getUnitChoices = async () => {
  try {
    const response = await axios.get(`/api/shared-lists/unit-choices/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getSharedList = async () => {
  try {
    const response = await axios.get(`/api/shared-lists/`);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

const createSharedListItem = async (body) => {
  try {
    const response = await axios.post(`/api/shared-lists/`, body);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error(error?.response?.data?.error);
  }
};

const updateSharedListItem = async (body) => {
  try {
    const response = await axios.put(`/api/shared-lists/`, body);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error(error?.response?.data?.error);
  }
};

const deleteSharedListItem = async (itemId) => {
  try {
    const response = await axios.delete(`/api/shared-lists/items/${itemId}/`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAssignments,
  createAssignments,
  seriesEditAssignment,
  getParticipants,
  AddCrewInHub,
  editUser,
  uploadProfilePicture,
  uploadHubPictures,
  getFormChoices,
  getProfileDetails,
  getHubProfileDetails,
  submitProfileDetails,
  submitHubDetails,
  deleteUserData,
  registerUser,
  loginUser,
  getlogin,
  createHub,
  getCurrentUser,
  logoutUser,
  getHubDetails,
  getHubFormChoices,
  getHubAssignments,
  getHubCrew,
  getUserDetailsByEmail,
  RemoveCrewFromHub,
  editAssignment,
  deleteAssignment,
  searchCrew,
  searchHub,
  sendInvitationRequest,
  viewInvitationRequest,
  confirmInvitationRequest,
  sendFCMToken,
  getNotifications,
  putNotification,
  getAssignmentDetails,
  submitFeedback,
  support,
  leaveHub,
  verifyEmail,
  makeAdmin,
  removeAdmin,
  cancelInvitationRequest,
  getUnitChoices,
  createSharedListItem,
  getSharedList,
  updateSharedListItem,
  deleteSharedListItem,
};
