import React, { useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import deleteIcon from "../../../assets/images/SVG/delete.svg";
import doneIcon from "../../../assets/images/SVG/done.svg";
import { Button, Form, Input, InputNumber, message, Select, Space } from "antd";
import "./GroceryForm.styles.scss";
import { useMutation, useQueryClient } from "react-query";
import api from "../../../api/api";

const { Option } = Select;
const GroceryForm = ({ unitChoices }) => {
  const [isFormVisible, setFormVisible] = useState(false);
  const queryClient = useQueryClient();
  const groceryList = queryClient.getQueryData("sharedGroceryList");
  const submitMutation = useMutation({
    mutationFn: async (postData) => {
      if (groceryList && groceryList.length > 0) {
        return await api.updateSharedListItem(postData);
      } else {
        return await api.createSharedListItem(postData);
      }
    },
    onSuccess: () => {
      message.success("List item added successfully!");
      queryClient.invalidateQueries("sharedGroceryList");
      setFormVisible(false);
    },
    onError: () => {
      message.error("Failed to add the item. Please try again.");
    },
  });

  const onFinish = async (values) => {
    const postData = {
      shared_list_name: "Grocery List",
      ...values,
    };

    if (groceryList && groceryList.length > 0) {
      submitMutation.mutate({
        ...postData,
        shared_lists_id: groceryList[0]?.shared_lists_id,
      });
    } else {
      submitMutation.mutate(postData);
    }
  };

  const handleAddClick = () => {
    setFormVisible(true);
  };
  return (
    <>
      {!isFormVisible ? (
        <Button className="add-grocery-btn" onClick={handleAddClick}>
          <span className="btn-text">Add new</span>
          <PlusCircleOutlined />
        </Button>
      ) : (
        <Form
          name="grocery-form"
          initialValues={{
            list_items: [
              { list_items_name: "Onions", item_qty: 2, quantity_unit: "kg" },
            ],
          }}
          onFinish={onFinish}
          style={{
            maxWidth: 400,
          }}
          autoComplete="off"
        >
          <Form.List name="list_items">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      // marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item {...restField}>
                      <Space.Compact>
                        <Form.Item
                          className="item_name"
                          name={[name, "list_items_name"]}
                          rules={[
                            {
                              required: true,
                              message: "Item name is required",
                            },
                          ]}
                        >
                          <Input placeholder="Item name" />
                        </Form.Item>
                        <Form.Item
                          className="item_qty"
                          name={[name, "item_qty"]}
                          rules={[
                            {
                              required: true,
                              message: "Quantity is required",
                            },
                          ]}
                        >
                          <InputNumber min={1} placeholder="Quantity" />
                        </Form.Item>
                        <Form.Item
                          className="quantity_unit"
                          name={[name, "quantity_unit"]}
                          rules={[
                            {
                              required: true,
                              message: "Unit is required",
                            },
                          ]}
                        >
                          <Select placeholder="Unit">
                            {unitChoices?.map((choice) => (
                              <Option key={choice?.label} value={choice?.value}>
                                {choice?.value}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Button
                          className="action-btn delete"
                          onClick={() => {
                            remove(name);
                            setFormVisible(false);
                          }}
                        >
                          <img
                            height="20px"
                            weight="20px"
                            src={deleteIcon}
                            alt="edit"
                          />
                        </Button>
                        <Button className="action-btn done" htmlType="submit">
                          <img
                            height="12.5px"
                            weight="12.5px"
                            src={doneIcon}
                            alt="edit"
                          />
                        </Button>
                      </Space.Compact>
                    </Form.Item>
                  </Space>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      )}
    </>
  );
};

export default GroceryForm;
