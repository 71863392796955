import React, { createContext, useContext } from "react";
import { useQuery } from "react-query";
import api from "../api/api";

// Create the context
const UnitChoicesContext = createContext();

// Custom hook to access the context
export const useUnitChoices = () => {
  return useContext(UnitChoicesContext);
};

// Context Provider
export const UnitChoicesProvider = ({ children }) => {
  // Use react-query to fetch the data
  const { data, error, isLoading } = useQuery(
    "unitChoices",
    api.getUnitChoices
  );

  return (
    <UnitChoicesContext.Provider value={{ data, error, isLoading }}>
      {children}
    </UnitChoicesContext.Provider>
  );
};
