import { Card, Spin } from "antd";
import ProfileForm from "../Forms/ProfileForm/ProfileForm.component";
import "./EditProfile.styles.scss";
import { useQuery } from "react-query";
import api from "../../api/api";
import { useLocation } from "react-router";
import { useState } from "react";

const EditProfile = () => {
  const location = useLocation();
  const [hubPreviewPage, setHubPreviewPage] = useState(false);
  const isEditProfilePage = location.pathname.includes("edit-profile");
  const queryKey = isEditProfilePage ? "formChoices" : "hubformChoices";

  const { data: radioOptions, isLoading } = useQuery(queryKey, async () => {
    if (isEditProfilePage) {
      return await api.getFormChoices();
    } else {
      return await api.getHubFormChoices();
    }
  });

  const onPreviewClick = () => {
    setHubPreviewPage(true);
  };
  const onPreviousClick = () => {
    setHubPreviewPage(false);
  };

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div
      className={
        !hubPreviewPage ? `editProfile-container` : "preview-page-container"
      }
    >
      <Card className="editUserCard">
        <ProfileForm
          radioOptions={radioOptions}
          onPreviewClick={onPreviewClick}
          hubPreviewPage={hubPreviewPage}
          onPreviousClick={onPreviousClick}
        />
      </Card>
    </div>
  );
};

export default EditProfile;
